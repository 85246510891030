export default {
  notAllowTitle: "Impossible",
  notAllowText: "Vous n'avez pas les droits d'effectuer cette action",
  allNotAllowText: 
    "Cet élément est fourni en standard par AAB Vie. Il n'est ni supprimable, ni modifiable.",
    super_administrator_sys: {
      // Politique Juridique
      PrivacyAndSecurity: {
      view: true,
      },
    // Super Administrateurs
    users: {
      create: true,
      send: true,
      update: true,
      delete: true,
      initialize: true, // Reinitialiser le mot de passe
      lock: true, // Activation / Desactivation
      all: true,
      show: true,
    },
    broker: {
      show:true,
      create: true,
      send: true,
      update: true,
      delete: true,
      initialize: true, // Reinitialiser le mot de passe
      lock: true, // Activation / Desactivation
      all: true
    },
    mailing: {
      show: true,
      create: true,
      send: true,
      update: true,
      delete: true,
      all: true
    },
    mail_received: {
      show: true,
      create: true,
      send: true,
      all: true
    },
    mail_client: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
    ticket: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
    ongoing_project: {
      delete:true,
      update: true,
      show: true,
      all: true,
    },
    slips: {
      show: true,
      create: true,
      all: true,
      update: true,
      delete: true,
    },
    customer_document: {
      show: true,
      create: true,
      all: true,
      update: true,
      delete: true,
    },
    customer_account: {
      show: true,
      create: true,
      all: true,
      update: true,
      delete: true,
      send: true,
      initialize:true,
      lock: true, // Activation / Desactivation


    },
    subscription:{
      show: true,
      create: true,
      all: true,
      update: true,
      send: true,
      delete: true,
      lock: false, // Activation / Desactivation
      initialize:true,
      comment : true
    },
    contract_issue: {
      change: true,
      cancel: true,
      show: true,
      all: true,
      update: true,
      delete: true,
    },
  },
  administrator_sys: {
    // Politique Juridique
      PrivacyAndSecurity: {
      view: false,
    },
    // Administrateurs
    users: {
      create: true,
      send: true,
      update: true,
      delete: true,
      initialize: true, // Reinitialiser le mot de passe
      lock: true, // Activation / Desactivation
      all: true,
      show: true,
    },
    broker: {
      show:true,
      create: true,
      send: true,
      update: true,
      delete: true,
      initialize: true, // Reinitialiser le mot de passe
      lock: true, // Activation / Desactivation
      all: true
    },
    mailing: {
      show: true,
      create: true,
      send: true,
      update: true,
      delete: true,
      all: true
    },
    mail_received: {
      show: true,
      create: true,
      send: true,
      all: true
    },
    mail_client: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
    ticket: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
    ongoing_project: {
      delete:true,
      update: true,
      show: true,
      all: true,
    },
    slips: {
      show: true,
      create: true,
      all: true,
       update: true,
      delete: true,
    },
    customer_document: {
      show: true,
      create: false,
      all: true,
      update: false,
      delete: false,

    },
    customer_account: {
      show: true,
      create: true,
      all: true,
      update: true,
      send: true,
      delete: false,
      lock: false, // Activation / Desactivation
      initialize:true

    },
    subscription:{
      show: true,
      create: true,
      all: true,
      update: true,
      send: true,
      delete: true,
      lock: false, // Activation / Desactivation
      initialize:true,
      comment : true

    },
    contract_issue: {
      change: false,
      cancel: false,
      show: true,
      all: true,
      update: true,
      delete: true,
    },
  },
  gestionnaire_sys: {
    // Politique Juridique
      PrivacyAndSecurity: {
      view: false,
    },
    // Gestionnaire
    users: {
      create: false,
      send: false,
      update: false,
      delete: false,
      initialize: false, // Reinitialiser le mot de passe
      lock: false, // Activation / Desactivation
      all: false,
      show: true,
    },
    broker: {
      show:true,
      create: false,
      send: false,
      update: false,
      delete: false,
      initialize: false, // Reinitialiser le mot de passe
      lock: false, // Activation / Desactivation
      all: false
    },
    mailing: {
      show: false,
      create: false,
      send: false,
      update: false,
      delete: false,
      all: false
    },
    mail_received: {
      show: false,
      create: false,
      send: false,
      all: false
    },
    mail_client: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
    ticket: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
    ongoing_project: {
      delete:false,
      update: true,
      show: true,
      all: true,
    },
    slips: {
      show: true,
      create: true,
      all: true,
       update: true,
      delete: true,
    },
    customer_document: {
      show: true,
      create: true,
      all: true,
      update: true,
      delete: false,
    },
    customer_account: {
      show: true,
      create: false,
      all: true,
      update: false,
      send: true,
      delete: false,
      lock: false, // Activation / Desactivation
      initialize:false

    },
    subscription:{
      show: true,
      create: true,
      all: true,
      update: true,
      send: true,
      delete: false,
      lock: false, // Activation / Desactivation
      initialize:true,
      comment : true

    },
    contract_issue: {
      change: false,
      cancel: false,
      show: true,
      all: true,
      update: true,
      delete: false,
    },
  },
  medical_advisor_sys: {
    // Politique Juridique
      PrivacyAndSecurity: {
      view: false,
    },
    // medecin-conseil
    users: {
      create: false,
      send: false,
      update: false,
      delete: false,
      initialize: false, // Reinitialiser le mot de passe
      lock: false, // Activation / Desactivation
      all: false,
      show: true,
    },
    broker: {
      show:true,
      create: false,
      send: false,
      update: false,
      delete: false,
      initialize: false, // Reinitialiser le mot de passe
      lock: false, // Activation / Desactivation
      all: false
    },
    mailing: {
      show: false,
      create: false,
      send: false,
      update: false,
      delete: false,
      all: false
    },
    mail_received: {
      show: false,
      create: false,
      send: false,
      all: false
    },
    mail_client: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
    ticket: {
      create: true,
      update: true,
      show: true,
      all: true,
    },
    ongoing_project: {
      delete:false,
      update: false,
      show: true,
      all: true,
    },
    slips: {
      show: true,
      create: true,
      all: true,
       update: true,
      delete: true,
    },
    customer_document: {
      show: true,
      create: false,
      all: true,
      update: false,
      delete: false,
    },
    customer_account: {
      show: true,
      create: false,
      all: true,
      update: false,
      send: true,
      delete: false,
      lock: false, // Activation / Desactivation
      initialize:false
    },
    subscription:{
      show: true,
      create: true,
      all: true,
      update: true,
      send: true,
      delete: false,
      lock: false, // Activation / Desactivation
      initialize:true,
      comment : true

    },
    contract_issue: {
      change: false,
      cancel: false,
      show: true,
      all: true,
      update: true,
      delete: false,
    },
  }
};
