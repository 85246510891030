/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'

Vue.component('aab-vie-mails', () => import('./components/aab-vie/mail/aab-vie-mail.vue'))
Vue.component('vx-input-group', () => import('./components/vx-input-group/VxInputGroup.vue'))
Vue.component('feather-icon', () => import('./components/FeatherIcon.vue'))
Vue.component('vx-breadcrumb', () => import('./layouts/components/VxBreadcrumb.vue'))
Vue.component('vx-list', () => import('./components/vx-list/VxList.vue'))
Vue.component('vx-card', () => import('./components/vx-card/VxCard.vue'))
Vue.component('vx-tooltip', () => import('./layouts/components/vx-tooltip/VxTooltip.vue'))
Vue.component('aab-vie-doc-client', () => import('./components/aab-vie/aab-vie-doc-client/aab-vie-doc-client.vue'))
Vue.component('aab-vie-subscribe-active-contrat', () => import('./components/aab-vie/souscription/ActiveContract/index.vue')) 
Vue.component('aab-vie-subscribe-term-contrat', () => import('./components/aab-vie/souscription/TermContract/index.vue'))
Vue.component('aab-vie-subscribe-contract-issue', () => import('./components/aab-vie/souscription/ContractIssue/index.vue'))
Vue.component('aab-vie-subscribe-contracts-soon-inactive', () => import('./components/aab-vie/souscription/ContractsSoonInactive/index.vue'))
Vue.component('aab-vie-subscribe-inactive-contracts', () => import('./components/aab-vie/souscription/InactiveContracts/index.vue'))
Vue.component('aab-vie-subscribe-study-subscription', () => import('./components/aab-vie/souscription/StudySubscription/index.vue'))
Vue.component('aab-vie-subscribe-comment', () => import('./components/aab-vie/souscription/Comment/index.vue'))
Vue.component('aab-vie-subscribe-mark-payment', () => import('./components/aab-vie/souscription/Payment/MarkPayment/index.vue'))
Vue.component('aab-vie-subscribe-enabled-police', () => import('./components/aab-vie/souscription/EnabledPolice/index.vue'))
Vue.component('aab-vie-subscribe-relaunch', () => import('./components/aab-vie/souscription/Relaunch/index.vue'))
Vue.component('aab-vie-subscribe-avenants', () => import('./components/aab-vie/souscription/Avenant/index.vue'))
Vue.component('aab-vie-subscribe-enabled-avenant', () => import('./components/aab-vie/souscription/EnabledAvenant/index.vue'))
Vue.component('aab-vie-subscribe-send', () => import('./components/aab-vie/souscription/SendSubscription/index.vue'))
Vue.component('aab-vie-subscribe-rejected-subscription', () => import('./components/aab-vie/souscription/RejectedSubscription/index.vue'))
Vue.component('aab-vie-subscribe-contrat-evolution', () => import('./components/aab-vie/souscription/ContratEvolution/index.vue'))
Vue.component('aab-vie-subscribe-general-information', () => import('./components/aab-vie/souscription/GeneralInformations/index.vue'))
Vue.component('aab-vie-subscribe-receipt', () => import('./components/aab-vie/souscription/Receipt/index.vue'))
Vue.component('aab-vie-subscribe-detail-quittance', () => import('./components/aab-vie/souscription/DetailQuittance/index.vue'))

// v-select component
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1'
      }
    }),
  },
  OpenIndicator: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5'
      }
    }),
  },
});

Vue.component(vSelect)
